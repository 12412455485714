export class CourseRegistration {
  static setup() {
    $(document).on('click', '#saving', function(event) {
      const container = '#attendance_' + $(this).data().status.toString();
      $(container).data('initialValue', $(container).val());
      const attendance_time_field = '#partial_time_attended_' + $(this).data().status.toString();
      $(attendance_time_field).data('initialValue', $(attendance_time_field).val());
      if ($(container).val() !== 'partial_attend') {
        $(attendance_time_field).val(null);
      }
    });

    $(document).on('click', '#save_all', function(event) {
      $('*[id*=saving]').each(function() {
        return $(this).click();
      });
    });

    if ( $('.simple_form.edit_course_registration')[0] ) {
      $(':input').each(function() {
        $(this).data('initialValue', $(this).val());
      });
    }

    const onRegistrationEmailNowOrLaterChange = () => {
      const submit = $('button[type="submit"]')[0];
      const icon = submit.children[0];
      if ($('#course_registration_email_now_or_later').val() === 'Send Later') {
        $('.course_registration_email_send_at').removeClass('d-none');
        $(icon).removeClass('fa-paper-plane').addClass('fa-calendar');
        submit.innerText = 'Schedule Email to Registrants';
      } else {
        $('.course_registration_email_send_at').addClass('d-none');
        $('.course_registration_email_send_at input').val('');
        $(icon).removeClass('fa-calendar').addClass('fa-paper-plane');
        submit.innerText = 'Send Email to Registrants';
      }
      submit.prepend(icon);
    }

    if (!!$('#course_registration_email_now_or_later').val()) {
      onRegistrationEmailNowOrLaterChange();
      $('#course_registration_email_now_or_later').change(() => {
        onRegistrationEmailNowOrLaterChange();
      });

      $('.course_registration_email_send_at input').focusout(() => {
        if (moment($('.course_registration_email_send_at input').val()) <= moment()) {
          $('.course_registration_email_send_at input').val(moment().format('YYYY-MM-DDTHH:m'));
        }
      });
    }

    $('.simple_form.new_course_registration_email').submit(function(event) {
      event.preventDefault();
      if (moment($('.course_registration_email_send_at input').val()) <= moment()) {
        if (window.confirm('Are you sure? The send at time is on or before the current time, the email will send now.')) {
          this.submit();
        }
      } else {
        this.submit();
      }
    });

    return $('#emailCopyButton').click(async function() {
      const emails = $('.active-registrant-emails .list-group-item').toArray().map((i) => i.innerText).join(', ');
      await navigator.clipboard.writeText(emails);
      return alert('Copied to clipboard!');
    });
  }
}

import $ from 'jquery';
import moment from 'moment';
import 'fullcalendar/dist/fullcalendar.min.css';
import '../src/main.scss';
window.$ = $;
window.jQuery = $;
import { CalendarSetup } from '../src/calendarSetup';
import { CourseRegistration } from '../src/course_registration';
window.moment = moment;

$(document).ready(function() {
    CalendarSetup.setup();
    CourseRegistration.setup();
});
